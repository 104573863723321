var render = function () {
  var _vm$readingErrorActio, _vm$readingErrorActio2, _vm$readingErrorActio3, _vm$readingErrorActio4;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "data-cy": "asset-accounts-readings-page"
    }
  }, [_c('TabTitle', {
    staticClass: "mb-4",
    attrs: {
      "icon": "fa-input-numeric",
      "action-position": "right"
    },
    scopedSlots: _vm._u([{
      key: "sub-title",
      fn: function fn() {
        return [_vm._v("View and add readings to this account.")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('button', {
          staticClass: "btn btn-primary",
          attrs: {
            "data-cy": "add-new-btn"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.onClickSubmitReading($event);
            }
          }
        }, [_c('i', {
          staticClass: "fas fa-fw fa-plus"
        }), _vm._v(" NEW")])];
      },
      proxy: true
    }])
  }, [[_vm._v("Readings")]], 2), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center mb-4"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "d-flex align-items-center mr-3"
  }, [_c('strong', {
    staticClass: "mr-2"
  }, [_vm._v("Date Range")]), _c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "dateRange",
      "type": "dateRangePicker",
      "config": {
        time: true
      },
      "disabled": _vm.loadingAction.getReadings
    },
    on: {
      "input": _vm.refresh
    },
    model: {
      value: _vm.dateRange,
      callback: function callback($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mr-3"
  }, [_c('strong', {
    staticClass: "mr-2"
  }, [_vm._v("Read Type")]), _c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "search",
      "type": "select",
      "options": [{
        label: 'All',
        value: ''
      }, {
        label: 'Actual',
        value: 'A'
      }, {
        label: 'Estimate',
        value: 'E'
      }, {
        label: 'Customer',
        value: 'C'
      }, {
        label: 'Direct',
        value: 'D'
      }, {
        label: 'Automatic',
        value: 'R'
      }],
      "disabled": _vm.loadingAction.getReadings
    },
    on: {
      "input": _vm.refresh
    },
    model: {
      value: _vm.filters.type,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "type", $$v);
      },
      expression: "filters.type"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mr-3"
  }, [_c('strong', {
    staticClass: "mr-2"
  }, [_vm._v("Source")]), _c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "search",
      "type": "select",
      "options": [{
        label: 'All',
        value: ''
      }, {
        label: 'Manual',
        value: 'manual'
      }, {
        label: 'Invoice',
        value: 'invoice'
      }, {
        label: 'Sub Meter',
        value: 'sub meter'
      }, {
        label: 'Import',
        value: 'import'
      }],
      "disabled": _vm.loadingAction.getReadings
    },
    on: {
      "input": _vm.refresh
    },
    model: {
      value: _vm.filters.source,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "source", $$v);
      },
      expression: "filters.source"
    }
  })], 1)]), _c('Pagination', {
    attrs: {
      "current-page": _vm.pagination.page,
      "total-pages": _vm.pagination.totalPages,
      "loading": _vm.loadingAction.getReadings,
      "summary": ""
    },
    on: {
      "change": _vm.onChangePage
    }
  })], 1), _vm.showAdd ? _c('div', {
    staticClass: "block",
    attrs: {
      "data-cy": "readings-add"
    }
  }, [_vm.submitStatus === 'uploading' ? _c('div', {
    staticClass: "block-content bg-body-light"
  }, [_c('Spinner', {
    staticClass: "push"
  }, [_vm._v("Uploading photo... " + _vm._s(_vm.photoUploadProgress) + "%")])], 1) : _vm.submitStatus === 'creating' ? _c('div', {
    staticClass: "block-content bg-body-light"
  }, [_c('Spinner', {
    staticClass: "push"
  }, [_vm._v("Submitting reading...")])], 1) : _vm.submitStatus === 'error' ? _c('div', {
    staticClass: "alert alert-danger"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-times-circle text-danger"
  }), _vm._v(" There was a problem. "), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.submitStatus = 'pending';
      }
    }
  }, [_vm._v("Click here")]), _vm._v(" to try again. ")]) : _vm.submitStatus === 'completed' ? _c('div', {
    staticClass: "alert alert-success"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-check-circle text-success"
  }), _vm._v(" Reading was submitted successfully. "), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.submitStatus = 'pending';
      }
    }
  }, [_vm._v("Click here")]), _vm._v(" to submit another. ")]) : _c('div', [_c('div', {
    staticClass: "block-content bg-lightest"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('h4', {
    staticClass: "mb-0"
  }, [_vm._v("Submit/Edit a reading")]), _c('button', {
    staticClass: "btn btn-link text-danger fa-lg",
    attrs: {
      "data-cy": "contract-close"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.showAdd = false;
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-times"
  })])]), _c('p', [_vm._v("Use the form below to submit or edit a reading. You can also attach an image to accompany the reading.")]), _vm.readingErrorAction.submitReading && _vm.readingErrorAction.submitReading.message === 'Form validation error' || _vm.readingErrorAction.editSubmission && _vm.readingErrorAction.editSubmission.message === 'Form validation error' ? _c('div', {
    staticClass: "alert alert-danger"
  }, [_vm._v(" There was an issue submitting this reading ")]) : _vm._e(), _c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-xl-6"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xl-8"
  }, [_c('FormGroup', {
    staticClass: "mb-1",
    attrs: {
      "id": "date",
      "label": "Date",
      "description": "Date time above is in your local timezone",
      "type": "dateTimePicker",
      "config": {
        utc: false
      },
      "error": _vm.validationErrors.submittedAt
    },
    model: {
      value: _vm.form.submittedAt,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "submittedAt", $$v);
      },
      expression: "form.submittedAt"
    }
  }), _c('div', {
    staticClass: "font-size-sm text-muted font-w600 mb-3"
  }, [_vm._v("UTC: " + _vm._s(_vm._f("date")(_vm.form.submittedAt, 'DD/MM/YYYY HH:mm', null, true)))]), _c('FormGroup', {
    attrs: {
      "id": "value",
      "label": "Value",
      "type": "text",
      "error": ((_vm$readingErrorActio = _vm.readingErrorAction.submitReading) === null || _vm$readingErrorActio === void 0 ? void 0 : (_vm$readingErrorActio2 = _vm$readingErrorActio.formMessages) === null || _vm$readingErrorActio2 === void 0 ? void 0 : _vm$readingErrorActio2.value) || ((_vm$readingErrorActio3 = _vm.readingErrorAction.editSubmission) === null || _vm$readingErrorActio3 === void 0 ? void 0 : (_vm$readingErrorActio4 = _vm$readingErrorActio3.formMessages) === null || _vm$readingErrorActio4 === void 0 ? void 0 : _vm$readingErrorActio4.value)
    },
    model: {
      value: _vm.form.value,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "value", $$v);
      },
      expression: "form.value"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "type",
      "label": "Type",
      "type": "select",
      "options": [{
        label: 'Actual',
        value: 'A'
      }, {
        label: 'Estimate',
        value: 'E'
      }, {
        label: 'Customer',
        value: 'C'
      }, {
        label: 'Direct',
        value: 'D'
      }, {
        label: 'Automatic',
        value: 'R'
      }],
      "error": _vm.validationErrors.type
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }), _vm.registerOptions.length > 1 ? _c('FormGroup', {
    attrs: {
      "id": "registerId",
      "label": "Register",
      "type": "select",
      "options": _vm.registerOptions,
      "error": _vm.validationErrors.registerId
    },
    model: {
      value: _vm.form.registerId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "registerId", $$v);
      },
      expression: "form.registerId"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "mb-3 bg-light p-3 rounded-md"
  }, [_c('div', {
    staticClass: "font-w600 mb-1"
  }, [_vm._v("Meter Overrides")]), _c('div', {
    staticClass: "font-size-sm font-w600 text-muted mb-3"
  }, [_vm._v(" By default the meter's units and factors will be used. Only override these if you are aware of the implications this may have. ")]), _c('div', {
    staticClass: "row mb-2"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('FormGroup', {
    staticClass: "mb-0",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "id": "units",
      "placeholder": "Default (".concat(_vm.selectedAccount.meterUnits, ")"),
      "label": "Units",
      "type": "select",
      "options": _vm.unitOptions,
      "error": _vm.validationErrors.units,
      "config": {
        allowClear: true
      }
    },
    model: {
      value: _vm.form.units,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "units", $$v);
      },
      expression: "form.units"
    }
  })], 1), _c('div', {
    staticClass: "col-md-5"
  }, [_c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "factor",
      "placeholder": "None",
      "label": "Factor",
      "error": _vm.validationErrors.factor
    },
    model: {
      value: _vm.form.factor,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "factor", $$v);
      },
      expression: "form.factor"
    }
  })], 1)])])], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xl-8"
  }, [_c('FormGroup', {
    attrs: {
      "id": "referenceOnly",
      "label": "Reference Only",
      "description": "Select 'Yes' to exclude this reading in consumption calculations, reports and data validation",
      "type": "radio",
      "options": [{
        label: 'Yes',
        value: true
      }, {
        label: 'No',
        value: false
      }],
      "error": _vm.validationErrors.referenceOnly
    },
    model: {
      value: _vm.form.referenceOnly,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "referenceOnly", $$v);
      },
      expression: "form.referenceOnly"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "isReset",
      "label": "Meter Reset",
      "description": "Is this the first reading since the meter has been reset/replaced",
      "type": "radio",
      "options": [{
        label: 'Yes',
        value: true
      }, {
        label: 'No',
        value: false
      }],
      "error": _vm.validationErrors.isReset
    },
    model: {
      value: _vm.form.isReset,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isReset", $$v);
      },
      expression: "form.isReset"
    }
  }), _vm.activeContract && _vm.activeContract.rates.length > 0 ? _c('FormGroup', {
    attrs: {
      "id": "contractRateType",
      "label": "Contract Rate Name",
      "type": "select",
      "options": _vm.contractRateOptions
    },
    model: {
      value: _vm.form.contractRateType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "contractRateType", $$v);
      },
      expression: "form.contractRateType"
    }
  }) : _vm._e(), _c('label', [_vm._v("Photo")]), _c('FileUpload', {
    staticClass: "mb-2",
    attrs: {
      "file-types": [],
      "existing-file-name": _vm.file.filename
    },
    on: {
      "reset": function reset($event) {
        _vm.file.filename = null;
      }
    },
    model: {
      value: _vm.file,
      callback: function callback($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  })], 1)]), _c('button', {
    staticClass: "btn btn-primary push",
    on: {
      "click": _vm.onClickSubmit
    }
  }, [_vm._v("Submit")])])])])])]) : _vm._e(), _vm.loadingAction.getReadings ? _c('SpinnerLogo', [_vm._v("Loading readings...")]) : _vm.readings.length > 0 ? _c('div', {
    attrs: {
      "id": "accordion",
      "role": "tablist"
    }
  }, [_vm.selectedAccount.factor !== undefined && _vm.selectedAccount.factor !== null && _vm.selectedAccount.factor !== 1 ? _c('div', {
    staticClass: "alert alert-info font-w500 mb-2"
  }, [_c('i', {
    staticClass: "fa fa-calculator mr-1"
  }), _vm._v(" This meter has a non-standard factor of " + _vm._s(_vm.selectedAccount.factor) + ". Any readings that do not have a factor specified below will default to " + _vm._s(_vm.selectedAccount.factor) + ". ")]) : _vm._e(), _c('div', {
    staticClass: "font-w600 mb-2 text-muted"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-clock text-info"
  }), _vm._v(" Times are in UTC")]), _vm._l(_vm.readings, function (reading) {
    var _reading$type;

    return _c('div', {
      key: reading._id,
      staticClass: "bg-lightest px-4 py-3 rounded-md mb-4"
    }, [_c('div', {
      staticClass: "row align-items-center"
    }, [_c('div', {
      staticClass: "col-xl-2 col-lg-4 font-size-h5 font-w600"
    }, [_c('div', [_vm._v(_vm._s(_vm._f("date")(reading.submittedAt, 'Do MMM YYYY', null, true)))]), _c('div', {
      staticClass: "text-muted font-size-sm"
    }, [_vm._v(_vm._s(_vm._f("date")(reading.submittedAt, 'HH:mm:ss', null, true)))])]), _c('div', {
      staticClass: "col-xl-5 col-lg-8"
    }, [_c('div', {
      staticClass: "font-w600 mr-2"
    }, [reading.isReset ? _c('span', {
      staticClass: "text-danger font-size-sm mr-1"
    }, [_vm._v("METER RESET")]) : _vm._e(), _vm._v(_vm._s(reading.value)), ((_reading$type = reading.type) === null || _reading$type === void 0 ? void 0 : _reading$type.toUpperCase()) !== 'A' ? _c('ReadType', {
      staticClass: "ml-1",
      attrs: {
        "read-type": reading.type
      }
    }) : _vm._e()], 1), _c('small', [_vm._v("Submitted by "), _c('strong', [_vm._v(_vm._s(_vm._f("user")(reading.userSub, _vm.users)))]), _vm._v(" on "), _c('strong', [_vm._v(_vm._s(_vm._f("date")(reading.createdAt, 'Do MMM YYYY HH:mm:ss')))])]), _c('div', [_c('span', {
      staticClass: "badge badge-secondary mr-2"
    }, [_vm._v(_vm._s(reading.units || _vm.selectedAccount.meterUnits))]), _c('span', {
      staticClass: "badge badge-warning text-capitalize mr-2",
      class: {
        'badge-info': reading.source === 'manual',
        'badge-primary': reading.source === 'invoice'
      }
    }, [_vm._v(_vm._s(reading.source))]), _vm.selectedAccount.registerIds.length > 0 ? _c('span', {
      staticClass: "badge badge-success mr-2"
    }, [_vm._v("Register " + _vm._s(reading.registerId || '1'))]) : _vm._e(), reading.contractRateType ? _c('span', {
      staticClass: "badge text-capitalize badge-info mr-2"
    }, [_vm._v(_vm._s(reading.contractRateType))]) : _vm._e(), reading.factor !== undefined && reading.factor !== null && (_vm.selectedAccount.factor === 1 && reading.factor !== 1 || _vm.selectedAccount.factor !== 1) ? _c('span', {
      staticClass: "badge badge-light mr-2"
    }, [_vm._v("Factor: " + _vm._s(reading.factor))]) : _vm._e()])]), _c('div', {
      staticClass: "col-xl-5 col-lg-12 text-right mt-xl-0 mt-4"
    }, [_vm.$permissions.write('account', _vm.selectedAccount) ? _c('button', {
      staticClass: "btn btn-link font-w600 text-secondary mr-2",
      attrs: {
        "disabled": !reading.s3Key || _vm.loadingAction.updateReading || _vm.loadingAction.getReadings || _vm.loadingAction.getPhoto
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.onClickPhoto(reading);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-camera"
    }), _vm._v(" " + _vm._s(_vm.loadingAction.getPhoto ? 'Loading...' : 'Photo') + " ")]) : _vm._e(), _vm.$permissions.write('account', _vm.selectedAccount) ? _c('button', {
      staticClass: "btn btn-link font-w600 text-secondary mr-2",
      attrs: {
        "disabled": _vm.loadingAction.updateReading || _vm.loadingAction.getReadings
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.onClickEdit(reading);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-pencil"
    }), _vm._v(" " + _vm._s(_vm.loadingAction.updateReading ? 'Updating...' : 'Edit') + " ")]) : _vm._e(), _vm.$permissions.write('account', _vm.selectedAccount) ? _c('button', {
      staticClass: "btn btn-link font-w600 text-danger",
      attrs: {
        "disabled": _vm.loadingAction.deleteReading || _vm.loadingAction.getReadings
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.onClickDelete(reading);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-trash"
    }), _vm._v(" " + _vm._s(_vm.loadingAction.deleteReading === reading._id ? 'Deleting...' : 'Delete') + " ")]) : _vm._e()])])]);
  })], 2) : _c('div', {
    staticClass: "alert alert-warning",
    attrs: {
      "role": "alert"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("No readings found within the selected filters.")])]), _c('Pagination', {
    staticClass: "mb-4",
    attrs: {
      "current-page": _vm.pagination.page,
      "total-pages": _vm.pagination.totalPages,
      "loading": _vm.loadingAction.getReadings,
      "summary": ""
    },
    on: {
      "change": _vm.onChangePage
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }