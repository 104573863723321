<template>
  <span class="badge text-capitalize" :class="readTypeClass">{{ parsedReadType }}</span>
</template>
<script>
export default {
  name: 'ReadType',
  props: {
    invoice: {
      type: Object,
      required: false,
      default: () => ({})
    },
    readType: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    parsedReadType() {
      if (this.readType) return this.readType;

      let rates = [];

      if (this.invoice.rates && this.invoice.rates.length > 0) {
        rates = this.invoice.rates;
      } else if (this.invoice.detailedResults && this.invoice.detailedResults.rates && this.invoice.detailedResults.rates.length > 0) {
        rates = this.invoice.detailedResults.rates;
      }

      const rateWithReadType = rates.find(rate => rate.endReadType);

      const overviewReadType = this.invoice.values ? this.invoice.values.type : null;

      let readType = null;

      if (rateWithReadType && rateWithReadType.endReadType) {
        readType = rateWithReadType.endReadType;
      } else if (overviewReadType) {
        readType = overviewReadType;
      } else if (this.invoice.type === 'electricity' && (this.invoice.values.meterPointNumber || '').slice(0, 2) === '00') {
        readType = 'A';
      } else {
        readType = 'A';
      }

      return readType;
    },
    readTypeClass() {
      if (!this.parsedReadType) return null;

      const badReads = ['e', 'er', 'fe', 'd', 'estimated', 'est', 'ee'];

      return badReads.includes(this.parsedReadType.toLowerCase()) ? 'badge-danger' : 'badge-success';
    }
  }
};
</script>
