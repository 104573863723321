<template>
  <div data-cy="asset-accounts-readings-page">
    <TabTitle class="mb-4" icon="fa-input-numeric" action-position="right">
      <template>Readings</template>
      <template #sub-title>View and add readings to this account.</template>
      <template #actions>
        <button data-cy="add-new-btn" class="btn btn-primary" @click.prevent="onClickSubmitReading"><i class="fas fa-fw fa-plus"></i> NEW</button>
      </template>
    </TabTitle>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <div class="d-flex">
        <div class="d-flex align-items-center mr-3">
          <strong class="mr-2">Date Range</strong>
          <FormGroup
            id="dateRange"
            v-model="dateRange"
            class="mb-0"
            type="dateRangePicker"
            :config="{ time: true }"
            :disabled="loadingAction.getReadings"
            @input="refresh"
          />
        </div>
        <div class="d-flex align-items-center mr-3">
          <strong class="mr-2">Read Type</strong>
          <FormGroup
            id="search"
            v-model="filters.type"
            class="mb-0"
            type="select"
            :options="[
              { label: 'All', value: '' },
              { label: 'Actual', value: 'A' },
              { label: 'Estimate', value: 'E' },
              { label: 'Customer', value: 'C' },
              { label: 'Direct', value: 'D' },
              { label: 'Automatic', value: 'R' }
            ]"
            :disabled="loadingAction.getReadings"
            @input="refresh"
          />
        </div>
        <div class="d-flex align-items-center mr-3">
          <strong class="mr-2">Source</strong>
          <FormGroup
            id="search"
            v-model="filters.source"
            class="mb-0"
            type="select"
            :options="[
              { label: 'All', value: '' },
              { label: 'Manual', value: 'manual' },
              { label: 'Invoice', value: 'invoice' },
              { label: 'Sub Meter', value: 'sub meter' },
              { label: 'Import', value: 'import' }
            ]"
            :disabled="loadingAction.getReadings"
            @input="refresh"
          />
        </div>
      </div>
      <Pagination
        :current-page="pagination.page"
        :total-pages="pagination.totalPages"
        :loading="loadingAction.getReadings"
        summary
        @change="onChangePage"
      />
    </div>

    <div v-if="showAdd" data-cy="readings-add" class="block">
      <div v-if="submitStatus === 'uploading'" class="block-content bg-body-light">
        <Spinner class="push">Uploading photo... {{ photoUploadProgress }}%</Spinner>
      </div>
      <div v-else-if="submitStatus === 'creating'" class="block-content bg-body-light">
        <Spinner class="push">Submitting reading...</Spinner>
      </div>
      <div v-else-if="submitStatus === 'error'" class="alert alert-danger">
        <i class="fa fa-fw fa-times-circle text-danger"></i> There was a problem.
        <a href="#" @click.prevent="submitStatus = 'pending'">Click here</a> to try again.
      </div>
      <div v-else-if="submitStatus === 'completed'" class="alert alert-success">
        <i class="fa fa-fw fa-check-circle text-success"></i> Reading was submitted successfully.
        <a href="#" @click.prevent="submitStatus = 'pending'">Click here</a> to submit another.
      </div>
      <div v-else>
        <div class="block-content bg-lightest">
          <div class="d-flex justify-content-between">
            <h4 class="mb-0">Submit/Edit a reading</h4>
            <button data-cy="contract-close" class="btn btn-link text-danger fa-lg" @click.prevent="showAdd = false">
              <i class="fa fa-times"></i>
            </button>
          </div>
          <p>Use the form below to submit or edit a reading. You can also attach an image to accompany the reading.</p>
          <div
            v-if="
              (readingErrorAction.submitReading && readingErrorAction.submitReading.message === 'Form validation error') ||
              (readingErrorAction.editSubmission && readingErrorAction.editSubmission.message === 'Form validation error')
            "
            class="alert alert-danger"
          >
            There was an issue submitting this reading
          </div>
          <div class="row align-items-center">
            <div class="col-xl-6">
              <div class="row">
                <div class="col-xl-8">
                  <FormGroup
                    id="date"
                    v-model="form.submittedAt"
                    class="mb-1"
                    label="Date"
                    description="Date time above is in your local timezone"
                    type="dateTimePicker"
                    :config="{ utc: false }"
                    :error="validationErrors.submittedAt"
                  />
                  <div class="font-size-sm text-muted font-w600 mb-3">UTC: {{ form.submittedAt | date('DD/MM/YYYY HH:mm', null, true) }}</div>

                  <FormGroup
                    id="value"
                    v-model="form.value"
                    label="Value"
                    type="text"
                    :error="readingErrorAction.submitReading?.formMessages?.value || readingErrorAction.editSubmission?.formMessages?.value"
                  />

                  <FormGroup
                    id="type"
                    v-model="form.type"
                    label="Type"
                    type="select"
                    :options="[
                      { label: 'Actual', value: 'A' },
                      { label: 'Estimate', value: 'E' },
                      { label: 'Customer', value: 'C' },
                      { label: 'Direct', value: 'D' },
                      { label: 'Automatic', value: 'R' }
                    ]"
                    :error="validationErrors.type"
                  />

                  <FormGroup
                    v-if="registerOptions.length > 1"
                    id="registerId"
                    v-model="form.registerId"
                    label="Register"
                    type="select"
                    :options="registerOptions"
                    :error="validationErrors.registerId"
                  />

                  <div class="mb-3 bg-light p-3 rounded-md">
                    <div class="font-w600 mb-1">Meter Overrides</div>
                    <div class="font-size-sm font-w600 text-muted mb-3">
                      By default the meter's units and factors will be used. Only override these if you are aware of the implications this may have.
                    </div>
                    <div class="row mb-2">
                      <div class="col">
                        <FormGroup
                          id="units"
                          v-model="form.units"
                          :placeholder="`Default (${selectedAccount.meterUnits})`"
                          label="Units"
                          type="select"
                          class="mb-0"
                          :options="unitOptions"
                          :error="validationErrors.units"
                          :config="{ allowClear: true }"
                          style="width: 100%"
                        />
                      </div>
                      <div class="col-md-5">
                        <FormGroup
                          id="factor"
                          v-model="form.factor"
                          class="mb-0"
                          placeholder="None"
                          label="Factor"
                          :error="validationErrors.factor"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-8">
                  <FormGroup
                    id="referenceOnly"
                    v-model="form.referenceOnly"
                    label="Reference Only"
                    description="Select 'Yes' to exclude this reading in consumption calculations, reports and data validation"
                    type="radio"
                    :options="[
                      { label: 'Yes', value: true },
                      { label: 'No', value: false }
                    ]"
                    :error="validationErrors.referenceOnly"
                  />

                  <FormGroup
                    id="isReset"
                    v-model="form.isReset"
                    label="Meter Reset"
                    description="Is this the first reading since the meter has been reset/replaced"
                    type="radio"
                    :options="[
                      { label: 'Yes', value: true },
                      { label: 'No', value: false }
                    ]"
                    :error="validationErrors.isReset"
                  />

                  <FormGroup
                    v-if="activeContract && activeContract.rates.length > 0"
                    id="contractRateType"
                    v-model="form.contractRateType"
                    label="Contract Rate Name"
                    type="select"
                    :options="contractRateOptions"
                  />

                  <label>Photo</label>
                  <FileUpload v-model="file" class="mb-2" :file-types="[]" :existing-file-name="file.filename" @reset="file.filename = null" />
                </div>
              </div>
              <button class="btn btn-primary push" @click="onClickSubmit">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <SpinnerLogo v-if="loadingAction.getReadings">Loading readings...</SpinnerLogo>

    <div v-else-if="readings.length > 0" id="accordion" role="tablist">
      <div
        v-if="selectedAccount.factor !== undefined && selectedAccount.factor !== null && selectedAccount.factor !== 1"
        class="alert alert-info font-w500 mb-2"
      >
        <i class="fa fa-calculator mr-1"></i> This meter has a non-standard factor of {{ selectedAccount.factor }}. Any readings that do not have a
        factor specified below will default to {{ selectedAccount.factor }}.
      </div>
      <div class="font-w600 mb-2 text-muted"><i class="fa fa-fw fa-clock text-info"></i> Times are in UTC</div>
      <div v-for="reading in readings" :key="reading._id" class="bg-lightest px-4 py-3 rounded-md mb-4">
        <div class="row align-items-center">
          <div class="col-xl-2 col-lg-4 font-size-h5 font-w600">
            <div>{{ reading.submittedAt | date('Do MMM YYYY', null, true) }}</div>
            <div class="text-muted font-size-sm">{{ reading.submittedAt | date('HH:mm:ss', null, true) }}</div>
          </div>

          <div class="col-xl-5 col-lg-8">
            <div class="font-w600 mr-2">
              <span v-if="reading.isReset" class="text-danger font-size-sm mr-1">METER RESET</span>{{ reading.value
              }}<ReadType v-if="reading.type?.toUpperCase() !== 'A'" class="ml-1" :read-type="reading.type" />
            </div>
            <small
              >Submitted by <strong>{{ reading.userSub | user(users) }}</strong> on
              <strong>{{ reading.createdAt | date('Do MMM YYYY HH:mm:ss') }}</strong></small
            >
            <div>
              <span class="badge badge-secondary mr-2">{{ reading.units || selectedAccount.meterUnits }}</span>
              <span
                class="badge badge-warning text-capitalize mr-2"
                :class="{ 'badge-info': reading.source === 'manual', 'badge-primary': reading.source === 'invoice' }"
                >{{ reading.source }}</span
              >
              <span v-if="selectedAccount.registerIds.length > 0" class="badge badge-success mr-2">Register {{ reading.registerId || '1' }}</span>
              <span v-if="reading.contractRateType" class="badge text-capitalize badge-info mr-2">{{ reading.contractRateType }}</span>
              <span
                v-if="
                  reading.factor !== undefined &&
                  reading.factor !== null &&
                  ((selectedAccount.factor === 1 && reading.factor !== 1) || selectedAccount.factor !== 1)
                "
                class="badge badge-light mr-2"
                >Factor: {{ reading.factor }}</span
              >
            </div>
          </div>

          <div class="col-xl-5 col-lg-12 text-right mt-xl-0 mt-4">
            <button
              v-if="$permissions.write('account', selectedAccount)"
              class="btn btn-link font-w600 text-secondary mr-2"
              :disabled="!reading.s3Key || loadingAction.updateReading || loadingAction.getReadings || loadingAction.getPhoto"
              @click.prevent="onClickPhoto(reading)"
            >
              <i class="fa fa-fw fa-camera"></i> {{ loadingAction.getPhoto ? 'Loading...' : 'Photo' }}
            </button>
            <button
              v-if="$permissions.write('account', selectedAccount)"
              class="btn btn-link font-w600 text-secondary mr-2"
              :disabled="loadingAction.updateReading || loadingAction.getReadings"
              @click.prevent="onClickEdit(reading)"
            >
              <i class="fa fa-fw fa-pencil"></i> {{ loadingAction.updateReading ? 'Updating...' : 'Edit' }}
            </button>
            <button
              v-if="$permissions.write('account', selectedAccount)"
              class="btn btn-link font-w600 text-danger"
              :disabled="loadingAction.deleteReading || loadingAction.getReadings"
              @click.prevent="onClickDelete(reading)"
            >
              <i class="fa fa-fw fa-trash"></i> {{ loadingAction.deleteReading === reading._id ? 'Deleting...' : 'Delete' }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="alert alert-warning" role="alert">
      <p class="mb-0">No readings found within the selected filters.</p>
    </div>
    <Pagination
      class="mb-4"
      :current-page="pagination.page"
      :total-pages="pagination.totalPages"
      :loading="loadingAction.getReadings"
      summary
      @change="onChangePage"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import { downloadFileViaApi } from '@/lib/downloadManager';

import FileUpload from '@/components/FileUpload';
import FormGroup from '@/components/FormGroup';
import Pagination from '@/components/Pagination';
import Spinner from '@/components/Spinner';
import SpinnerLogo from '@/components/SpinnerLogo';
import TabTitle from '@/components/base/TabTitle';
import ReadType from '@/components/ReadType';

const initialForm = {
  submittedAt: moment.utc().startOf('day').toDate(),
  value: 0,
  type: 'A',
  units: null,
  registerId: '1',
  referenceOnly: false,
  contractRateType: null,
  search: '',
  isReset: false
};

export default {
  name: 'AssetAccountReadings',
  components: {
    FileUpload,
    FormGroup,
    Pagination,
    ReadType,
    Spinner,
    SpinnerLogo,
    TabTitle
  },
  data() {
    return {
      showAdd: false,
      initialLoad: true,
      form: initialForm,
      file: {
        filename: null
      },
      submitStatus: 'pending',
      filters: {
        type: '',
        source: ''
      },
      mode: 'create',
      pagination: {
        page: 1,
        totalPages: 1,
        perPage: 30
      },
      dateRange: [moment().subtract(1, 'year').startOf('day').toDate(), moment().add(1, 'year').endOf('day').toDate()]
    };
  },
  computed: {
    ...mapGetters({
      asset: 'asset/asset',
      readings: 'account/readings',
      totalReadings: 'account/totalReadings',
      photoUploadProgress: 'reading/photoUploadProgress',
      selectedAccount: 'asset/selectedAccount',
      loadingAction: 'account/loadingAction',
      errorAction: 'account/errorAction',
      readingErrorAction: 'reading/errorAction',
      users: 'company/users',
      validationErrors: 'account/validationErrors',
      readingSchema: 'reading/schema',
      units: 'util/units'
    }),
    activeContract() {
      return this.selectedAccount.contracts.find(contract => contract.status === 'active');
    },
    contractRateOptions() {
      return [{ label: '-', value: null }, ...this.activeContract.rates.map(rate => ({ label: rate.type, value: rate.type }))];
    },
    unitOptions() {
      return [...this.units.filter(u => u.types.includes(this.selectedAccount.type)).map(u => ({ label: u.name, value: u.value }))];
    },
    registerOptions() {
      return [
        { label: '1 (Default)', value: '1' },
        ...(this.selectedAccount.registerIds || []).map(registerId => ({ label: registerId, value: registerId }))
      ];
    }
  },
  async mounted() {
    await Promise.all([this.listUnits(), this.getReadingSchema(), this.listUsers({ id: this.selectedAccount.companyId }), this.refresh()]);
  },
  destroyed() {
    this.reset();
  },
  methods: {
    ...mapActions({
      listReadings: 'account/getReadings',
      uploadPhoto: 'reading/uploadPhoto',
      getPhoto: 'reading/getPhoto',
      submitReading: 'reading/submitReading',
      delete: 'account/deleteReading',
      download: 'account/downloadContract',
      update: 'reading/editSubmission',
      listUsers: 'company/listUsers',
      getReadingSchema: 'reading/schema',
      listUnits: 'util/listUnits'
    }),
    ...mapMutations({
      reset: 'account/RESET_STATE'
    }),
    async refresh() {
      const filters = Object.assign(
        {},
        ...Object.entries(this.filters)
          .filter(value => value[1])
          .map(([key, value]) => ({ [key]: value }))
      );

      await this.listReadings({
        data: {
          params: {
            ...filters,
            $sort: 'submittedAt:-1',
            $skip: (this.pagination.page - 1) * this.pagination.perPage,
            $limit: this.pagination.perPage,
            accountId: this.selectedAccount._id,
            'submittedAt.gte': moment(this.dateRange[0]).format('YYYY-MM-DD[T]HH:mm'),
            'submittedAt.lte': moment(this.dateRange[1]).format('YYYY-MM-DD[T]HH:mm')
          }
        }
      });

      this.pagination.totalPages = Math.ceil(this.totalReadings / this.pagination.perPage);
    },
    onChangePage(pageNumber) {
      if (pageNumber > this.pagination.totalPages) {
        this.pagination.page = this.pagination.totalPages;
      }

      if (pageNumber < 1) {
        this.pagination.page = 1;
      }

      this.pagination.page = pageNumber;

      this.refresh();
    },
    async onClickDelete(reading) {
      const update = await this.delete({ id: reading._id });
      if (update) {
        this.$toasted.success('Deleted reading successfully.', { position: 'bottom-center', duration: 3000 });
        this.refresh();
      } else {
        this.$toasted.error('Could not delete reading.', { position: 'bottom-center', duration: 3000 });
      }
    },
    async onClickSubmit() {
      let update = null;
      this.submitStatus = 'uploading';

      try {
        if (this.file.file) {
          const photoUpload = await this.uploadPhoto({ data: this.file.file });
          this.form.s3Key = photoUpload.s3Key;
        }

        this.submitStatus = 'creating';

        if (this.mode === 'create') {
          update = await this.submitReading({
            data: {
              ...this.form,
              companyId: this.selectedAccount.companyId,
              entityId: this.selectedAccount.entityId,
              accountId: this.selectedAccount._id
            }
          });
        } else {
          update = await this.update({ id: this.form._id, data: { ...this.form } });
        }

        if (update._id) {
          this.submitStatus = 'completed';

          this.file = {};
          this.resetForm();
        } else {
          this.submitStatus = 'pending';
        }
      } catch (e) {
        this.submitStatus = 'error';
      }

      if (update) {
        this.$toasted.success('Submitted reading.', { position: 'bottom-center', duration: 3000 });
        this.refresh();
      } else {
        this.$toasted.error('Could not submit reading.', { position: 'bottom-center', duration: 3000 });
      }
    },
    onClickEdit(reading) {
      this.showAdd = true;
      this.file.filename = null;

      this.mode = 'edit';
      this.submitStatus = 'pending';

      if (reading.s3Key) this.file.filename = 'Photo';

      this.form = { ...reading };
    },
    onClickSubmitReading() {
      this.showAdd = !this.showAdd;

      this.mode = 'create';
      this.submitStatus = 'pending';

      this.resetForm();
    },
    getPhotoName(reading) {
      let invoiceName = `${this.selectedAccount.name}`;

      const capitalizedSource = `${reading.source.charAt(0).toUpperCase()}${reading.source.slice(1)}`;
      invoiceName += ` - ${capitalizedSource} - ${moment(reading.submittedAt).format('DDMMYYYY')} - Reading Photo.jpg`;

      return invoiceName;
    },
    async onClickPhoto(reading) {
      const fileName = this.getPhotoName(reading);

      downloadFileViaApi(fileName, this.getPhoto, {
        id: reading._id
      });
    },
    resetForm() {
      this.form = initialForm;
    }
  }
};
</script>
